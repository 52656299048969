import { Auth } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { useEffect, useState } from "react";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import * as Cookies from 'js-cookie'
import { authHost } from "./Login";

const logout = () => {
  console.log("logout")
  const callbackUrl = (window.location.href.includes('/logout') || window.location.href.includes('/login')) ? window.location.origin : window.location.href;
  window.location.href = encodeURI(`${authHost}/auth/login/?callbackUrl=${callbackUrl}`);
}

const getCurrentUser = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    if (currentUser) {
      currentUser.isAdmin = currentUser.signInUserSession.accessToken.payload["cognito:groups"]?.includes('admins');
      currentUser.hasResellerAccess = currentUser.isAdmin || !!(currentUser.signInUserSession.accessToken.payload["cognito:groups"] && currentUser.signInUserSession.accessToken.payload["cognito:groups"].includes('resellerAccess'));
      currentUser.hasDeviceAccess = currentUser.isAdmin || !!(currentUser.signInUserSession.accessToken.payload["cognito:groups"] && currentUser.signInUserSession.accessToken.payload["cognito:groups"].includes('deviceAccess'));
      currentUser.email = currentUser.attributes.email;
    }

    return currentUser;

  } catch (error) {
    // currentAuthenticatedUser throws an Error if not signed in
    console.log("getCurrentUser", error)
    logout()
    return null;
  }
};

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [supabaseUser, setSupabaseUser] = useState();
  /**
 * @type {[SupabaseClient | undefined, React.Dispatch<React.SetStateAction<SupabaseClient | undefined>>]}
 */
  const [supabase, setSupabase] = useState()

  useEffect(() => {
    if (!currentUser?.signInUserSession?.idToken?.payload?.supabaseUserId) {
      setSupabaseUser()
      return
    }

    const supabaseCookie = Cookies.get(`sb-${process.env.REACT_APP_SUPABASE_ID}-auth-token`)
    console.log({ supabaseCookie })

    if (!supabaseCookie) {
      console.log("!supabaseCookie")
      logout()
      return
    }

    const token = supabaseCookie?.split('"')?.[1]

    setSupabase(createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_ANON_KEY, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    ))
  }, [currentUser]);

  useEffect(() => {
    const updateUser = async () => {
      const user = await getCurrentUser();
      setCurrentUser(user);
    };

    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, []);

  useEffect(() => {
    if (supabase) {
      getSupabaseUser()
    }
  }, [supabase])

  const getSupabaseUser = async () => {
    const { data, error } = await supabase
      .from('users')
      .select()
      .eq('id', currentUser.signInUserSession.idToken.payload.supabaseUserId)
      .maybeSingle()

    if (error) {
      console.log("getSupabaseUser", error)
      logout()
    }
    setSupabaseUser(data)
  }

  const signIn = () => Auth.federatedSignIn();

  const signOut = () => Auth.signOut();

  return { currentUser, supabaseUser, supabase, signIn, signOut };
};

export default useAuth;

export { getCurrentUser };
